.columnContainer {
  width: 345px;
  display: flex;
}

.staticHeading {
  width: 260px;
  display: flex;
  flex-flow: column;
  border-right: solid 1px #eee;
}

.headingTitle {
  height: 50px;
  font-weight: bold;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #eee;
  background: #fff;
  padding: 8px;
  position: sticky;
  top: 55px;
  z-index: 1;
}

.productWrapper {
  border-bottom: solid 1px #eee;
  padding: 8px;
  white-space: nowrap;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.productImage {
  width: 42px;
  height: 42px;
}

.product {
  width: 205px;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.priceWrapper {
  min-width: 85px;
  display: flex;
  flex-flow: column;
  border-right: solid 1px #eee;
}

.price {
  border-bottom: solid 1px #eee;
  padding: 8px;
  height: 50px;
}